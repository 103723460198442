
.navbar {
  box-shadow: 0px 2px 6px #004896a7;
      &.scrolled {
        @media (min-width: 768px) {
          position: fixed;
          left: 0;
          right: 0;
          animation: scrolled 0.3s ease forwards;
        }
      }
  ul {
    display: flex;
    padding: 2%;
    a {
      text-decoration: none;
      padding-right: 15px;
      color: $color-3;
      font-family: $font-1;
      font-size: 1.15rem;
      &:hover {
        color: darken(#004996, 6%) !important;
      }
      &.active {
        color: lighten(#004996, 5%) !important;
      }
      li {
        color: $color-1;
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          color: $color-3;
        }
      }
    }
    
    .nav-active {
      position: relative;
          &::after {
            content: "";
            height: 4px;
            width: 0;
            background: #004996;
            position: absolute;
            border-radius: 10px;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-animation: anim 0.3s ease forwards;
            animation: anim 0.6s ease forwards;
            @-webkit-keyframes anim {
              to {
                width: 100%;
              }
            }
            @keyframes anim {
              to {
                width: 100%;
              }
            }
          }
        }
    .navbar-brand {
      font-weight: bold;
      letter-spacing: 2px;
      color: #4e4e4e;
      @media (min-width: 768px) {
        font-size: 1.8rem;
      }
      i {
        color: #004996;
        font-size: 0.5rem;
      }
    }

    .nav-link {
      text-transform: uppercase;
      font-weight: 700;
      
      color: #4e4e4e !important;
      font-size: 0.85rem;
      &:hover {
        color: darken(#004996, 6%) !important;
      }
      &.active {
        color: lighten(#004996, 5%) !important;
      }
      @media (min-width: 768px) {
        padding: 1rem 1rem;
      }
    }  
    .nav-icon {
      width: 30px;
      height: 25px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #4e4e4e;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2) {
          top: 9px;
        }
        &:nth-child(3) {
          top: 18px;
        }
      }
      &.open {
        span {
          &:nth-child(1) {
            top: 9px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            opacity: 0;
            left: -60px;
          }
          &:nth-child(3) {
            top: 9px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }
        }
      }
    }
  }
  img, svg {
    vertical-align:0%;
}

}
.top-bar {
  background-color: #004996;
  display: none;
  padding: 0 1rem;
  text-decoration: none;
  @media (min-width: 768px) {
    display: block;;
  }
}
