// *** Import de la font
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&family=Josefin+Sans:wght@100&family=Nova+Round&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// *** Codes des variants de fonts
$font-2: 'Dosis', sans-serif;
$font-3: 'Josefin Sans', serif;

@font-face {
  font-family: "font-1";
  src: url(../assets/Fonts/JosefinSans-Medium.ttf);
}

$font-1: "font-1", sans-serif;