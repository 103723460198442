.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    max-height: 90%; 
    overflow: auto; 
    z-index: 99999; 
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
      max-height: 50vh; 
      object-fit: contain; 
    }
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  