@import './colors';
@import './Fonts';

body {
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.section-header {
  .section-title {
    font-weight: bold;
    color: #4e4e4e;
    font-family: 'Raleway', sans-serif;
    position: relative;
    margin-bottom: 2rem;
    z-index: 1;
    span {
      color: #004996;
    }
    &:after {
      content: '';
      content: '';
      display: block;
      height: 3px;
      width: 70px;
      background: #004996;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .section-subtitle {
    max-width: 700px;
    color: #6f6f6f;
    font-size: 0.9rem;
  }
}


//Services

#services {
  background-color: #f6f8fb;
}
.service-box {
  padding: 2rem;
  border: 1px solid #eef1f5;
  margin-bottom: 1rem;
  background: white;
  .service-icon {
    font-size: 2rem;
    color: #004996;
  }
  .service-body {
    .service-title {
      font-size: 1.1rem;
      color: #4e4e4e;
      margin-bottom: 1.2rem;
    }
    .service-description {
      font-size: 0.9rem;
      color: #6f6f6f;
    }
  }
}

//Header
@keyframes scrolled {
  0% {
    top: -70px;
  }
  100% {
    top: 0;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @media (min-width: 768px) {
    position: absolute;
    
  }
  
  
}
