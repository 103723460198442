@import '../colors';
@import '../Fonts';

#NotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('../../assets/img/404-ship-crash-color.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  .notfound {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    #logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;
      margin-bottom: 1rem;
      
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    
    .big-404 {
      h1 {
        color: #555;
        font-size: 2em;
        font-weight: 400;
      }
    }
    
    .you-lost {
      h3 {
        color: #555;
      }
    }
    
    h2 {
      color: #555;
    }
    
    .section-subtitle {
      color: orangered;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
  }
  .logo {
    display: block;
    margin: 0 auto;
    max-width: 200px;
    height: auto;
  }
}
