@import '../colors';
@import '../Fonts';

footer {
    background-color: #004996;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    &:before {
      content: '';
      background-color: $color-1;
      opacity: 0.4;
      }
    .footer-title {
      position: relative;
      margin-bottom: 2rem;
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: $color-7;
        top: 30px;
      }
    }
    .bottom-footer {
      background-color: $white;
      color: $color-3;
    }
   
    .quick-links {
      list-style-type: none;
      padding: 0;
      margin: 0;
      }
      
      .social-media {
        color: $white !important;
      &:hover {
        color: lighten($color-7, 20%) !important;
      }
      a {
        text-decoration: none;
        font-size: 0.9rem;
        display: inline-block;
        padding: 0.3rem 0;
        color: $white !important;
        &:hover {
          color: lighten($color-7, 0%) !important;
        }
      }
    }
  }
  .social-media {
      color: $white !important;
    &:hover {
      color: lighten($color-6, 20%) !important;
    }

  }